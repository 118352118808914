<template>
  <v-row class="px-1">
    <schedule-pentest-dialog
      v-if="haveAvailablePlan && isSubs"
      :show-schedule-pentest-dialog.sync="newPentest"
      v-on:reload-items="initializeData"
      :subscriptions-plans="subscriptionsPlans"
    />

    <create-pentest-dialog
      v-if="!isSubs"
      v-model="newPentest"
      v-on:reload-items="initializeData"
    />

    <v-col cols="12" sm="6" md="3">
      <stats-card-variant
        bg-card-color="darken-5 red--text "
        heading="Critical Vulnerabilities"
        :sub-heading="allProjects.numVulnCritical"
        main-icon-text-color="primary--text text--lighten-2"
        main-icon-name="mdi-alert-circle"
      />
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <stats-card-variant
        bg-card-color="lighten-2 orange"
        heading="High Vulnerabilities"
        :sub-heading="allProjects.numVulnHigh"
        main-icon-text-color="light"
        main-icon-name="mdi-alert-circle"
      />
    </v-col>

    <v-col cols="12" sm="6" md="3">
      <stats-card-variant
        bg-card-color="yellow"
        heading="Moderate Vulnerabilities"
        :sub-heading="allProjects.numVulnModerate"
        main-icon-text-color="primary--text text--lighten-2"
        main-icon-name="mdi-alert-circle"
      />
    </v-col>

    <v-col cols="12" sm="6" md="3">
      <stats-card-variant
        bg-card-color="success"
        heading="Low Vulnerabilities"
        :sub-heading="allProjects.numVulnLow"
        main-icon-text-color="primary--text text--lighten-2"
        main-icon-name="mdi-alert-circle"
      />
    </v-col>

    <v-col cols="12" lg="8">
      <v-row>
        <v-col class="pt-0">
          <base-blue-card title="Vulnerability History">
            <div
              style="min-height: 265px"
              :class="showChart2 ? '' : 'd-flex justify-center'"
            >
              <apexchart
                v-if="showChart1"
                type="line"
                height="250"
                :options="chartOptionsLineColumn"
                :series="seriesLineColumn"
              />
              <no-data v-if="!showChart1" />
            </div>
          </base-blue-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" class="pb-0">
          <stats-card
            bg-card-color="white"
            heading="Planned"
            :sub-heading="totalProgress.numScheduled"
            main-icon-text-color="primary--text text--lighten-2"
            main-icon-name="mdi-calendar-clock"
          />
        </v-col>
        <v-col cols="12" lg="4" class="pb-0">
          <stats-card
            bg-card-color="white"
            heading="Currently working on"
            :sub-heading="totalProgress.numInProgress"
            main-icon-text-color="warning--text text--lighten-2"
            main-icon-name="mdi-progress-clock"
          />
        </v-col>
        <v-col cols="12" lg="4" class="pb-0">
          <stats-card
            heading="Completed & Delivered"
            :sub-heading="totalProgress.numCompleted"
            main-icon-text-color="success--text text--lighten-2"
            main-icon-name="mdi-check"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="4">
      <base-blue-card
        title="Vulnerability by Category"
      >
        <div
          style="min-height: 265px"
          :class="showChart2 ? '' : 'd-flex justify-center'"
        >
          <apexchart
            v-if="showChart2"
            type="radar"
            width="100%"
            height="370"
            :options="chartOptionsBar"
            :series="seriesBar"
          />
          <no-data v-if="!showChart2" />
        </div>
      </base-blue-card>
    </v-col>

    <v-col v-if="showTableP" cols="12" xl="6">
      <pentest-short-table :pentests="pentestsData" />
    </v-col>
    <v-col v-if="showTableR" cols="12" xl="6">
      <report-short-table class="pt-" :items="reportData" />
    </v-col>
    <!--<v-col
      v-if="isAdmin"
      cols="12"
      lg="4"
      md="6"
    >
      <v-col v-if="!showTableP" cols="12">
        <v-card>
          <div class="px-8 text-center ul-icon-box-animate-onhover">
            <div class="ul-icon-box mx-auto mb-3">
              <div class="bg-group ul-animate fallingClouds" />
              <br /><br />
              <v-img
                height="100"
                width="100"
                class="mx-auto"
                src="@/assets/images/svg/startup.svg"
              />
            </div>
            <div class="text-center pb-5">
              <h4 class="font-weight-bold">Let's kick off!</h4>
              <p class="text--lighten-1">Start another pentest project</p>
              <v-btn
                color="success"
                class="mr-1"
                depressed
                @click.stop="showAskPentest = true"
              >
                Schedule a Pentest
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-col>-->
  </v-row>
</template>
<script>
import PentestShortTable from '@/components/table/PentestShortTable'
import ReportShortTable from '@/components/table/ReportShortTable'
import StatsCard from '@/components/card/StatsCard'
import StatsCardVariant from '@/components/card/StatsCardVariant'
import PentestService from '@/services/pentest.service'
import StatsService from '@/services/stats.service.js'
import FilesService from '@/services/files.service.js'
import UserService from '@/services/user.service'
import SubscriptionsService from '@/services/subcriptions.service'
import CreatePentestDialog from '@/components/dialog/pentests/CreatePentestDialog.vue'

export default {
  name: 'dashboard',
  metaInfo: {
    title: 'Dashboard'
  },
  components: {
    PentestShortTable,
    ReportShortTable,
    StatsCard,
    StatsCardVariant,
    CreatePentestDialog,
    SchedulePentestDialog: () =>
      import('@/components/dialog/pentests/SchedulePentestDialog.vue'),
    NoData: () => import('@/components/base/NoData.vue')
  },
  data() {
    return {
      pentestsData: {},
      stats: {},
      totalProgress: {},
      allProjects: {},
      showAskPentest: false,
      showCreateAsset: false,
      isAdmin: false,
      showTableP: false,
      showTableR: false,
      showChart1: false,
      showChart2: false,
      reportData: [],
      newPentest: false,
      subscriptionsPlans: null,
      haveAvailablePlan: false,
      isSubs: false,
      isLock: true,

      seriesLineColumn: [
        {
          type: 'column',
          name: 'Monthly Total',
          data: []
        },
        {
          type: 'area',
          name: 'Critical',
          data: []
        }
      ],
      chartOptionsLineColumn: {
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value
            }
          },
          floating: false,
          decimalsInFloat: 0,
          title: {
            text: 'Total'
          }
        },
        stroke: {
          width: [0, 4],
          curve: 'smooth'
        },
        colors: ['#0070C0', '#f44336']
      },
      seriesBar: [
        {
          data: []
        }
      ],
      chartOptionsBar: {
        chart: {
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        colors: ['#0070C0'],
        dataLabels: {
          enabled: true
        },
        plotOptions: {
          radar: {
            size: 160,
            offsetY: 30
          }
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value
            }
          },
          floating: false,
          decimalsInFloat: 0
        }
      }
    }
  },
  created() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isAdmin = profile.role !== 'Client User'
      if (profile.client) {
        this.isSubs = profile.client.subscriptionSource === 'azure'
      }
    }
    this.initializeData()
  },
  mounted() {
    if (sessionStorage.nextAfter) {
      this.$router.push(sessionStorage.nextAfter)
    }
  },
  methods: {
    async checkClientSubscriptions() {
      await SubscriptionsService.getCheckSubscription().then((resp) => {
        this.subscriptionsPlans = resp
        this.haveAvailablePlan = this.subscriptionsPlans.total > 0
      }).catch(() => {})
    },
    transfertData() {
      this.totalProgress = this.stats.totalProgress
      this.allProjects = this.stats.allProjects
    },
    async initializeData() {
      const res = await PentestService.getPentests(1, 100)
      if (res.results) {
        this.pentestsData = res.results
        this.showTableP = true
        if (res.total === 0) {
          this.newPentest = true
        }
      }
      if (this.isAdmin) {
        await this.checkClientSubscriptions()
      }
      await this.initializeDataStats()
      await this.initializeDataReport()
    },
    async initializeDataStats() {
      const res = await StatsService.getStats()
      if (res) {
        this.stats = res
        this.transfertData()
        this.formateData()
        this.showChart1 = true
        this.checkDataTimeline(res.timeline)
        this.showChart2 = JSON.stringify(res.totalVulnCategories) !== '{}'
        this.$store.dispatch('changeThemeLoadingState', false)
      }
    },
    checkDataTimeline(data) {
      const timelime = Object.values(data)
      const array = []
      for (let i = 0; i < timelime.length; i++) {
        if (timelime[i].numCritical !== 0 && timelime[i].numTotal !== 0) {
          array.push('true')
        }
      }
      if (array.length === 0) {
        this.showChart1 = false
      }
    },
    async initializeDataReport() {
      const res = await FilesService.getReport()
      if (res.results) {
        this.reportData = res.results
        this.showTableR = true
      }
    },
    formateData() {
      const month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const data = this.stats.timeline
      const dataVC = this.stats.totalVulnCategories
      this.seriesBar[0].data = Object.values(dataVC)

      if (this.seriesBar.length === 0) {
        this.seriesBar = [0]
      }
      this.chartOptionsBar.labels = Object.keys(dataVC)
      if (this.chartOptionsBar.labels.length === 0) {
        this.chartOptionsBar.labels = ['No Data']
      }

      const newArray = []
      const dataTotalVuls = Object.values(data)
      for (let i = 0; i < dataTotalVuls.length; i++) {
        const res = dataTotalVuls[i].numTotal
        newArray.push(res)
      }
      this.seriesLineColumn[0].data = newArray

      const newArrayC = []
      const dataCriticalVuls = Object.values(data)
      for (let i = 0; i < dataCriticalVuls.length; i++) {
        const res = dataCriticalVuls[i].numCritical
        newArrayC.push(res)
      }
      this.seriesLineColumn[1].data = newArrayC
      const newArrayLabel = []
      const dataLabel = Object.keys(data) // .reverse()
      for (let i = 0; i < dataLabel.length; i++) {
        const res = month[parseInt(dataLabel[i].slice(-2) - 1)]
        newArrayLabel.push(res)
      }
      this.chartOptionsLineColumn.xaxis.categories = newArrayLabel
    }
  }
}
</script>
