<template>
  <div>
    <delete-file-dialog
      :edited-item="editedItem"
      :show-delete-dialog.sync="showDeleteDialog"
      v-on:reload-items="$emit('reload-items')"
    />
    <blue-card
      title="Reports"
      color-border="primary"
      icon="file-chart-outline"
      no-padding
    >
      <v-data-table
        :headers="headers"
        :items="localItems"
        :items-per-page="2"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar
            color="grey"
            class="my-5 d-none d-sm-flex"
            :class="[item.isDeleted ? 'error' : '']"
          >
            <v-icon
              medium
              class="my-2"
            >
              {{ setIconType(item.name).icon }}
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.name="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold resizeTextRST"
              :class="[item.isDeleted ? 'error--text' : '']"
            >
              {{ item.name }}
              <span
                v-if="item.isDeleted"
                class="error--text"
              >
                <v-icon color="error" large>mdi-delete-empty</v-icon>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ new Date (item.createdAt).toLocaleDateString(
              'en-CA')
            }}
          </div>
        </template>

        <template v-slot:item.type="{ item }">
          <v-chip
            v-if="item.type === 'attestation'"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ documentTypes[0].text }}
          </v-chip>
          <v-chip
            v-if="item.type === 'report_detailed'"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ documentTypes[1].text }}
          </v-chip>
          <v-chip
            v-if="item.type === null"
            class="text-capitalize"
            color="primary"
            outlined
          >
            Document
          </v-chip>
          <v-chip
            v-if="item.type === 'proposal'"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ documentTypes[3].text }}
          </v-chip>
          <v-chip
            v-if="item.type === 'notification'"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ documentTypes[4].text }}
          </v-chip>
          <v-chip
            v-if="item.type === 'report_summary'"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ documentTypes[5].text }}
          </v-chip>
        </template>

        <template v-slot:item.uploadBy="{ item }">
          <v-list-item-title
            class="font-weight-regular"
          >
            {{ item.uploadBy.fullName }}
          </v-list-item-title>
        </template>

        <template v-slot:item.file="{ item }">
          <span>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReport(item.file.id, item.file.name, item.file.type)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download File</span>
            </v-tooltip>
            <v-spacer />
          </span>
        </template>
      </v-data-table>
    </blue-card>
  </div>
</template>

<script>
import BlueCard from '../base/BlueCard.vue'
import FilesService from '@/services/files.service.js'
import DeleteFileDialog from '@/components/dialog/pentests/DeleteFileDialog.vue'
import { documentTypes, selectIconFileType } from '@/data/common'
import UserService from '@/services/user.service'

export default {
  components: {
    BlueCard,
    DeleteFileDialog
  },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: () => ({
    editedIndex: -1,
    editedItem: {},
    isSuperUser: false,
    showDeleteDialog: false,
    documentTypes,
    selectIconFileType,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'icon',
        width: '20px',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Date',
        value: 'createdAt',
        align: 'left',
        width: '116px',
        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'File',
        value: 'name',
        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'Type',
        value: 'type',
        align: 'center',
        sortable: false,
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Uploaded By',
        value: 'uploadBy',
        align: 'left',
        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'Actions',
        value: 'file',
        sortable: false,
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      }
    ]
  }),
  computed: {
    localItems() {
      return this.items
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
  },
  methods: {
    setIconType(filename) {
      let fileType = ""
      const extensions = filename.split(".")
      const lastExtension = extensions[extensions.length - 1]
      if (lastExtension.length >= 2 && lastExtension.length <= 8) {
        fileType = lastExtension
      } else {
        return { icon: "mdi-file-outline", color: "" }
      }

      const matchingIcon = this.selectIconFileType.find(el => el.text === fileType)

      if (matchingIcon) {
        return {
          icon: matchingIcon.value,
          color: matchingIcon.color
        }
      } else {
        return { icon: "mdi-file-outline", color: "" }
      }
    },
    async downloadReport(id, name, type) {
      await FilesService.getDownloadFileById(id, name, type)

      this.$swal({
        text: 'Download has just started!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok, got it!',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      }).then(() => {
        this.dialog = false
      })
    }
  }
}
</script>

<style lang="scss">
.resizeTextRST{
  word-break: break-word;
  inline-size: 210px;
}
</style>
