<template>
  <v-card flat>
    <v-card-text class="d-flex align-center h-full">
      <div
        class="d-flex flex-grow-1 justify-space-between align-center flex-wrap"
      >
        <div>
          <p class="ma-0">
            {{ heading }}
          </p>
          <div class="text-h4 ma-0 ">
            {{ subHeading ? subHeading : '-' }}
          </div>
        </div>
        <v-icon :class="mainIconTextColor" class="text-h3">
          {{ mainIconName }}
        </v-icon>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    props: ['mainIconTextColor', 'mainIconName', 'heading', 'subHeading'],
  }
</script>
