import api from './api'

class StatsService {
    async getStats () {
        const response = await api.get('/api/v1/stats/')
        return response.data
    }
}

export default new StatsService()
