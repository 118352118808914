<template>
  <v-card flat>
    <v-card-text class="d-flex align-center h-full">
      <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
        <div>
          <p class="ma-0">
            {{ heading }}
          </p>
          <div
            class="text-h3 ma-0 "
            :class="bgCardColor + '--text'"
          >
            {{ subHeading ? subHeading : '-' }}
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: ['bgCardColor', 'mainIconTextColor', 'mainIconName', 'heading', 'subHeading'],
  }
</script>
