<template>
  <div>
    <blue-card
      icon="rocket-launch-outline"
      title="Your Pentests"
      color-border="primary"
      no-padding
    >
      <v-data-table
        :headers="headers"
        :items="localPentests"
        :items-per-page="2"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-rocket-launch-outline
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.title="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold pointer primary--text text--lighten-2"
              @click="fullViewItem(item)"
            >
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="item.startDate"
              @click="fullViewItem(item)"
            >
              Started on: {{ item.startDate }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <!--
        <template v-slot:item.type="{ item }">
          <v-chip
            color="primary"
            outlined
            class="text-capitalize"
          >
            {{ item.type }}
          </v-chip>
        </template>
        -->
        <template v-slot:item.phaseNames="{ item }">
          <v-chip
            color="primary lighten-2"
            outlined
            class="text-capitalize my-2"
          >
            {{ item.phaseNames.length !== 0 ? setPhaseType(item.phaseNames[0]) : setPentestType(item.type) }}
          </v-chip>
          <v-tooltip bottom v-if="item.phaseNames.length > 1 ? item.phaseNames.length : false > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color=""
                icon
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon> More
              </v-chip>
            </template>
            <span v-for="(phase, index) in item.phaseNames.filter((phase, i) => i !== 0)" :key="index">
              <div>{{ setPhaseType(phase) }}</div>
            </span>
          </v-tooltip>
        </template>

        <template v-slot:item.data="{ item }">
          <v-chip>
            {{ item.data.numCritical + item.data.numHigh + item.data.numModerate + item.data.numLow }} Total
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'pending' || item.status === 'Created'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary lighten-2">mdi-creation-outline</v-icon>
              </v-avatar>
            </template>
            <span>Created</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'scheduled' || item.status === 'Ready to Start'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning">mdi-timer-play-outline</v-icon>
              </v-avatar>
            </template>
            <span>Ready to Start</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'in progress' || item.status === 'Testing'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning">mdi-test-tube</v-icon>
              </v-avatar>
            </template>
            <span>Testing</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'in redaction' || item.status === 'Reporting'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="purple">mdi-feather</v-icon>
              </v-avatar>
            </template>
            <span>Reporting</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'Report Ready'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="purple">mdi-file-document-check-outline</v-icon>
              </v-avatar>
            </template>
            <span>Report Ready</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'Report Sent'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="purple">mdi-file-send-outline</v-icon>
              </v-avatar>
            </template>
            <span>Report Sent</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'completed' || item.status === 'Done'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-check</v-icon>
              </v-avatar>
            </template>
            <span>Done</span>
          </v-tooltip>
        </template>

        <template v-slot:item.id="{ item }">
          <span>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="fullViewItem(item)"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>
            <v-spacer />
          </span>
        </template>
      </v-data-table>
    </blue-card>
  </div>
</template>

<script>
import BlueCard from '../base/BlueCard.vue'
import { pentestType, phasesOptions, tagPhasesOptions } from '@/data/common'

export default {
  components: {
    BlueCard
  },
  props: {
    pentests: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    showRetestDialog: false,
    editedIndex: -1,
    editedItem: {},
    pentestType,
    phasesOptions,
    tagPhasesOptions,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'icon',
        width: '20px',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Title',
        value: 'title',
        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'Phases',
        value: 'phaseNames',
        align: 'center',
        sortable: false,
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Vulnerabilities',
        value: 'data',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Actions',
        value: 'id',
        sortable: false,
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      }
    ]
  }),
  computed: {
    localPentests() {
      return this.pentests
    }
  },
  methods: {
    setPhaseType(type) {
      for (let i = 0; i < this.phasesOptions.length; i++) {
        if (this.phasesOptions[i].value === type) {
          return this.phasesOptions[i].text
        }
      }
      for (let i = 0; i < this.tagPhasesOptions.length; i++) {
        if (this.tagPhasesOptions[i].value === type) {
          return this.tagPhasesOptions[i].text
        }
      }
      // handle non-hardcoded tag phase
      return type
    },
    setPentestType(type) {
      for (let i = 0; i < this.pentestType.length; i++) {
        if (this.pentestType[i].value === type) {
          return this.pentestType[i].text
        }
      }
    },
    fullViewItem(pentest) {
      this.editedIndex = this.localPentests.indexOf(pentest)
      this.editedItem = Object.assign({}, pentest)
      this.$router.push(`/pentest/detail/${pentest.id}`)
    }
  }
}
</script>
